
* {
    //font-family: 'Montserrat Alternates', sans-serif;
    font-family: 'Krub', sans-serif;
    //font-family: 'Open Sans', sans-serif;
    margin: 0px;
    font-weight: 500;
}

html, body {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-image: url('../../assets/images/untitled_design.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: fixed;
}
