.row {
    align-items: center;
    justify-content: space-around;
}

h1 {
    background-color: white;
    border-radius: 10px;
    border: outset;
    font-weight: 900;
    text-align: -webkit-center;
}

li {
    padding-left: 5px;
}

.home__container {
    background-color: unset !important;
    border-radius: unset !important;
    border: unset !important;
    margin-top: 20px;
}

.home__unordered-list {
    background-color: white;
    border-radius: 10px;
    padding: 5px 10px 10px 5px;
    border: outset;
}

.home__first-page {
    height: 90vh;
    width: 100vw;
    margin-inline: auto;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.3);
}

.home__main-content {
    color: darken($color: black, $amount: 15);
}

.home__main-content ul{
    list-style-type: none;
    padding-inline-start: 0px;
}

.home__paragraph {
    text-align: justify;
    flex-shrink: unset;
    color: darken($color: black, $amount: 15);
    background-color: white;
    border-radius: 10px;
    border: outset;
    padding: 5px 10px 10px 5px;
    width: auto;
}

.home__picture {
    background-image: url('../../assets/images/homepic1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 40vh;
}

.home__picture2 {
    background-image: url('../../assets/images/homepic2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 40vh;
}

.fa-solid {
    color: $dark-yellow;
}

ul li{
    margin-bottom: 10px;
}

h1 {
    margin-bottom: 20px;
    color: $dark-blue;
}

h4 {
    color: darken($color: $dark-yellow, $amount: 15);
    font-weight: 900;
    
}

.card-title {
    color: $dark-blue;
}

.card-text {
    color: darken($color: $dark-yellow, $amount: 15);
}

.home__button {
    text-align-last: center;
}

.btn-info {
    background-color: $dark-blue;
    color: white;

    &:hover {
        color: darken($color: white, $amount: 20);
        background-color: lighten($color: $dark-blue, $amount: 20);
    }

}

.home__slide-title {
    margin: 20px;
    background: none;
    border: none;
}

.testimony__icon {
    background-image: url('../../assets/Thumbnail4.5.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 30px;
}

.home__chile-icon {
    background-image: url('../../assets/images/chile.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__colombia-icon {
    background-image: url('../../assets/images/colombia.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__argentina-icon {
    background-image: url('../../assets/images/argentina.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__us-icon {
    background-image: url('../../assets/images/united-states.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__canada-icon {
    background-image: url('../../assets/images/canada.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__belgium-icon {
    background-image: url('../../assets/images/belgium.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 30px;
    border-radius: 5px;
    margin-left: 10px;
}

.home__testimony-alignment {
    align-items: center;
    margin: 30px;
}

.card-text:last-child {
    margin-bottom: 0;
    display: flex;
}