.slide__slogan {
    color: $dark-blue;
    text-align: center;
    justify-content: center;
    font-weight: 900;
}

.carousel__caption {
    justify-content: center;
    color: $dark-yellow;
    font-weight: 900;
}

.slide__pic1 {
    background-image: url('../../assets/Transparent/Artboard_8.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 30vh;
}

.slide__img1 {
    background-image: url('../../assets/images/contact-pic1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 35vh;
    margin-bottom: 10px;
    opacity: 0.7;
}

.slide__pic2 {
    background-image: url('../../assets/images/slidepic3.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: -webkit-fill-available;
    margin-top: 20px;
    margin-left: 30px;
    width: 40%;
}
.slide__pic3 {
    background-image: url('../../assets/images/slidepic2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: -webkit-fill-available;
    margin-top: 20px;
    margin-left: 30px;
    width: 40%;
}

.slide__paragraph {
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    flex-shrink: unset;
    color: black;
    background-color: white;
    font-weight: 800;
    border-radius: 10px;
    border: outset;
    padding: 5px 10px 10px 5px;
    background: none;
    border: none;

    li {
        font-weight: 800;
    }
}

.card-text {
    color: black;
}

@media screen and (max-width: 767px){

    .slide__pic1 {
        height: 15vh;
    }

    .slide__img1 {
        height: 15vh;
    }
    
    .row {
        display: unset;
    }
    
    .slide__pic2 {
        margin-left: 30px;
        width: 80%;
        height: 30vh;
    }

    .slide__pic3 {
        margin-left: 30px;
        width: 80%;
        height: 30vh;
    }
    
    .slide__paragraph li {
        font-size: smaller;
    }

    .carousel-caption {
        position: unset;
    }

    .slide__paragraph {
        font-size: small;

        li {
            font-size: small;
        }
    }

    .home__slide-title {
        font-size: large;
    }
}

@media screen and (max-width: 653px){

    .slide__paragraph {
        font-size: xx-small;

        li {
            font-size: xx-small;
        }
    }

    .home__slide-title {
        font-size: large;
    }
}